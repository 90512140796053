<template>
  <div class="footer-container">
    <div class="site-info">
      <img
        src="https://cdn.shopify.com/s/files/1/0432/6644/1375/t/13/assets/logo-header.svg"
      />
      <p>{{companyMessage}}</p>
    </div>
    <!-- <transition @enter="showReportButton" @leave="hideReportButton" @before-enter="beforeReportEnter" name="toggle"> -->
      <div class="report-issue-btn" v-if="!currentRoute">
        <Button @click="handleReportIssue" type="info">
          <i class="fas icon-fa-exclamation-triangle"></i>&nbsp;&nbsp;REPORT ISSUES
        </Button>
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import gsap from 'gsap'
import Button from '@/components/buttons/Button'

export default {
  components: { Button },
  setup () {
    const route = useRoute()
    const router = useRouter()

    const currentRoute = computed(() => route.path === '/reportanissue')

    const companyMessage = computed(() => {
      const currentYear = new Date().getFullYear()
      const message = `© ${currentYear} BRExhaust - A MagnaFlow Group Company`
      return message
    })

    const showReportButton = (el, done) => {
      gsap.to(el, { duration: 0.5, autoAlpha: 1, onComplete: done })
    }

    const hideReportButton = (el, done) => {
      gsap.to(el, { duration: 0.5, autoAlpha: 0, onComplete: done })
    }

    const beforeReportEnter = (el) => {
      gsap.set(el, { autoAlpha: 0 })
    }

    const handleReportIssue = () => {
      router.push({ name: 'reportAnIssue', params: { url: window.location.href } })
    }

    return {
      currentRoute,
      showReportButton,
      hideReportButton,
      beforeReportEnter,
      handleReportIssue,
      companyMessage
    }
  }
}
</script>

<style  lang="scss">

.footer-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  .site-info{
    display: flex;
    flex-direction: row;
    flex-grow:1;
    align-items: center;
    font-size: 12px;
    img{
      height:$mf-footer-logo-height;
      margin-right:20px;
    }
    p{
      font-size: $mf-footer-font-size;
    }
  }
  .report-issue-btn{
    span{
      font-size:$mf-report-issue-btn-font-size;
    }
  }
}
</style>
